<script>
import { DateTime } from 'luxon'

export default {
  name: 'ViewPoaps',

  data() {
    return {
      loading: false,
      geolocationWatcherId: null,
      modeDiscovery: false
    }
  },

  computed: {
    collectiblePoaps() {
      return this.$poaps.discoveryPoaps?.filter(p => p.isCollectible) ?? []
    },

    detectorLabel() {
      if(this.hasClosePoaps) {
        return this.$t('conn3ct-wallet.poaps_detected', { count: this.$poaps.discoveryPoaps.length })
      }

      return this.$t('conn3ct-wallet.no_poaps_detected')
    },

    hasClosePoaps() {
      return this.$poaps.discoveryPoaps?.length > 0
    },

    orderedPoaps() {
      let dateFormat = { month: 'long', year: 'numeric' }
      let res = []

      this.$poaps.collectedPoaps.forEach(p => {
        let val = res.find(el => el.label === p.created.toLocaleString(dateFormat))

        if(val) {
          res = res.filter(el => el.label !== val.label)
          val.poaps.push(p)
        } else {
          val = {
            label: p.created.toLocaleString(dateFormat),
            poaps: [ p ]
          }
        }

        res.push(val)
      })

      return res
    },

    visiblePoaps() {
      return this.$poaps.discoveryPoaps?.filter(p => !p.isCollectible) ?? []
    },
  },

  methods: {
    brandLogo(logo) {
      return logo?.url?.all
    },

    checkPosition() {
      navigator.geolocation.getCurrentPosition(async ({ coords }) => {
        try {
          await this.$poaps.discover({ long: coords.longitude, lat: coords.latitude })
        } catch(e) {
          $console.error(e)
        } finally {
          this.geolocationWatcherId = setTimeout(this.checkPosition, 10000);
        }
      }, $console.error)
    },

    collectionDate(date) {
      return date?.toLocaleString(DateTime.DATETIME_SHORT)
    },

    async init() {
      this.loading = true

      try {
        await this.$poaps.getCollected()
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.init()
    this.checkPosition()
  },

  beforeDestroy() {
    clearTimeout(this.geolocationWatcherId)
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-poaps poaps"
    :loading="loading"
    :subtitle="$t('conn3ct-wallet.poaps_list_title_description')"
    :title="$t('conn3ct-wallet.poaps_list_title')"
  >
    <button
      v-if="!modeDiscovery"
      @click="modeDiscovery = true"
      class="poaps__detector"
      :class="{
        '-has-close-poaps': hasClosePoaps,
      }"
      :disabled="!hasClosePoaps"
    >
      <icon-egg />
      <span class="label">{{ detectorLabel }}</span>
    </button>

    <template v-if="!modeDiscovery">
      <ui-panel
        v-if="!orderedPoaps.length"
        class="poaps__section empty"
      >
        <template v-slot:header>
          <h3 class="title">{{ $t('conn3ct-wallet.no_poaps_for_filters_title') }}</h3>
        </template>

        <div class="body">
          <p>{{ $t('conn3ct-wallet.no_poaps_for_filters_description') }}</p>
        </div>
      </ui-panel>

      <template v-else>
        <section
          v-for="(section, i) in orderedPoaps"
          class="poaps__section"
          :key="i"
        >
          <h5 class="poaps__section-title">{{ section.label }}</h5>

          <layout-entity-list class="poaps__list">
            <ui-card
              v-for="poap in section.poaps"
              class="poaps__poap"
              :key="poap.id"
              :to="{ name: 'sayl-connect_poaps-poap', params: { id: poap.id } }"
              :title="poap.poapLocation.name"
            >
              <template v-slot:illustration>
                <ui-illustration
                  :alt="poap.name"
                  fit="contain"
                  :src="$basil.get(poap, 'poapLocation.collectibleImage')"
                />
              </template>

              <template
                v-if="$basil.get(poap, 'poapLocation.saylAppBrand.name')"
                v-slot:subtitle
              >
                <div class="poaps__poap-brand">
                  <ui-illustration
                    v-if="$basil.get(poap, 'poapLocation.saylAppBrand.logo')"
                    :alt="$basil.get(poap, 'poapLocation.saylAppBrand.name')"
                    class="poaps__poap-brand-logo"
                    :src="brandLogo(poap.poapLocation.saylAppBrand.logo)"
                  />

                  <span class="ui-card__subtitle">{{ $basil.get(poap, 'poapLocation.saylAppBrand.name') }}</span>
                </div>
              </template>

              <template v-slot:footer>
                <div class="row">
                  <p class="poaps__poap-date">{{ collectionDate(poap.created) }}</p>

                  <button class="poaps__poap-cta -short">
                    {{ $t('conn3ct-wallet.view') }}
                  </button>
                </div>
              </template>
            </ui-card>
          </layout-entity-list>
        </section>
      </template>
    </template>

    <div
      v-else
      class="poaps__discovery"
    >
      <template v-if="collectiblePoaps.length > 0 || visiblePoaps.length > 0">
        <section
          v-if="collectiblePoaps.length > 0"
          class="poaps__section"
        >
          <h5 class="poaps__section-title">{{ $t('conn3ct-wallet.poaps_list_collectible_title') }}</h5>

          <layout-entity-list class="poaps__list">
            <ui-card
              v-for="poap in collectiblePoaps"
              class="poaps__poap"
              :key="poap.id"
              :title="poap.name"
              :to="{ name: 'sayl-connect_poaps-collectible', params: { id: poap.id } }"
            >
              <template v-slot:illustration>
                <ui-illustration
                  :alt="poap.name"
                  fit="contain"
                  :src="$basil.get(poap, 'collectibleImage')"
                />
              </template>

              <template
                v-if="$basil.get(poap, 'saylAppBrand.name')"
                v-slot:subtitle
              >
                <div class="poaps__poap-brand">
                  <ui-illustration
                    v-if="$basil.get(poap, 'saylAppBrand.logo')"
                    :alt="$basil.get(poap, 'saylAppBrand.name')"
                    class="poaps__poap-brand-logo"
                    :src="brandLogo(poap.saylAppBrand.logo)"
                  />

                  <span class="ui-card__subtitle">{{ $basil.get(poap, 'saylAppBrand.name') }}</span>
                </div>
              </template>

              <template v-slot:footer>
                <div class="poaps__poap-footer">
                  <button class="poaps__poap-cta">
                    {{ $t('conn3ct-wallet.view') }}
                  </button>
                </div>
              </template>
            </ui-card>
          </layout-entity-list>
        </section>

        <section
          v-if="visiblePoaps.length > 0"
          class="poaps__section"
        >
          <h5 class="poaps__section-title">{{ $t('conn3ct-wallet.poaps_list_close_title') }}</h5>

          <layout-entity-list class="poaps__list">
            <ui-card
              v-for="poap in visiblePoaps"
              class="poaps__poap"
              :key="poap.id"
              :title="poap.name"
              :to="{ name: 'sayl-connect_poaps-collectible', params: { id: poap.id } }"
            >
              <template v-slot:illustration>
                <ui-illustration
                  :alt="poap.name"
                  fit="contain"
                  :src="$basil.get(poap, 'collectibleImage')"
                />
              </template>

              <template
                v-if="$basil.get(poap, 'saylAppBrand.name')"
                v-slot:subtitle
              >
                <div class="poaps__poap-brand">
                  <ui-illustration
                    v-if="$basil.get(poap, 'saylAppBrand.logo')"
                    :alt="$basil.get(poap, 'saylAppBrand.name')"
                    class="poaps__poap-brand-logo"
                    :src="brandLogo(poap.saylAppBrand.logo)"
                  />

                  <span class="ui-card__subtitle">{{ $basil.get(poap, 'saylAppBrand.name') }}</span>
                </div>
              </template>

              <template v-slot:footer>
                <div class="poaps__poap-footer">
                  <button class="poaps__poap-cta">
                    {{ $t('conn3ct-wallet.view') }}
                  </button>
                </div>
              </template>
            </ui-card>
          </layout-entity-list>
        </section>
      </template>
    </div>
  </layout-page>
</template>
